import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <div className="_customer-services_1kqab4">
        Any questions? Please{' '}
        <a href="https://contactus.progressgroup.org.uk/forms/contact-us/">
          contact us
        </a>{' '}
        or call the team on <br></br>03333 204555.
      </div>
      <div className="_copyright_1kqab4">© 2022 Progress Housing Group</div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
